<template>
  <Layout :loading="loading">
    <div v-if="currentCompany" class="container">
      <h1>Ajouter une entreprise liée à votre profil utilisateur</h1>
      <RegisterForm
        :submit="addCompanyToUser"
        :mail="username"
        :vat="currentCompany.vat"
        @afterSubmit="handleAfterSubmit"
      />
    </div>
  </Layout>
</template>

<script>
import { defineComponent, ref } from 'vue';
import { useRouter } from 'vue-router';

import RegisterForm from '@/components/Register/RegisterForm.vue';
import Layout from '@/components/common/Layout.vue';
import lowcoApi from '@/api/lowco-api';

import useAuthenticatedUser from '../composables/useAuthenticatedUser';
import useCurrentCompany from '../composables/useCurrentCompany';
import useCurrentCompanyDetails from '@/composables/useCurrentCompanyDetails';

export default defineComponent({
  name: 'CreateEnterprise',
  components: { RegisterForm, Layout },
  setup() {
    const router = useRouter();

    const { username, profileId } = useAuthenticatedUser();
    const {
      setCurrentCompanyId,
      fetchCompanies,
    } = useCurrentCompany();
    const { currentCompany } = useCurrentCompanyDetails();

    const loading = ref(false);

    const addCompanyToUser = (company) => lowcoApi.addCompanyToUser(company, profileId.value);

    const handleAfterSubmit = async (companyId) => {
      await fetchCompanies();
      setCurrentCompanyId(companyId);
      router.push('/profile');
    };

    return {
      username,
      addCompanyToUser,
      handleAfterSubmit,
      loading,
      currentCompany,
    };
  },
});
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
