import { ref, watch } from 'vue';

import lowcoApi from '@/api/lowco-api';

import useCurrentCompany from './useCurrentCompany';

const useCurrentCompanyDetails = () => {
  const { currentCompanyId } = useCurrentCompany();

  const currentCompany = ref(null);
  const loading = ref(false);

  const fetchCurrentCompany = async (id) => {
    loading.value = true;
    try {
      const result = await lowcoApi.getMyEnterprise(id);

      currentCompany.value = result;
    } catch {
      // Handle error
    } finally {
      loading.value = false;
    }
  };

  const updateCompany = async (companyToUpdate, onSuccess, onError) => {
    loading.value = true;

    try {
      const updatedCompany = await lowcoApi.updateCompany(companyToUpdate);

      currentCompany.value = updatedCompany;
      
      if(onSuccess) {
        onSuccess();
      }
    } catch (err) {
      if (onError) {
        onError(err);
      }
    } finally {
      loading.value = false;
    }
  };

  // Local function 'fetchCurrentCompany' is called when currentCompanyId changes
  // Or when a component which uses this hook gets mounted
  watch(currentCompanyId, fetchCurrentCompany, { immediate: true });

  return {
    currentCompany,
    fetchCurrentCompany,
    updateCompany,
    fetching: loading,
  };
};

export default useCurrentCompanyDetails;
